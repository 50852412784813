<template>
  <div class="modal modal_custom" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="columns is-multiline is-centered">
          <div class="column is-12">
            <p class="modal-card-title has-text-centered">Sube tu producto o servicio</p>
          </div>
          <div class="column is-12">
            <div class="block">
              <p class="font_14 has_gray has-text-centered">
                Ingresa los datos de tu servicio o producto
              </p>
            </div>
          </div>
          <div class="column is-12">
            <p class="font_14 has_gray has-text-weight-semibold">
              Elige una opción
            </p>
          </div>
          <div class="column is-12 has-text-centered">
            <div class="control">
              <div class="field">
                <input
                  class="is-checkradio"
                  id="services"
                  type="radio"
                  name="optionSelect"
                  :value="2"
                  v-model="optionSelect"
                  @change="() => { this.vverrors.clear() }"
                >
                <label for="services">Servicio</label>
                <input
                  class="is-checkradio"
                  id="product"
                  type="radio"
                  name="optionSelect"
                  :value="1"
                  v-model="optionSelect"
                  @change="() => { this.vverrors.clear() }"
                >
                <label for="product">Producto</label>
              </div>
            </div>
          </div>
          <div class="column is-12">
            <div class="field control">
              <label class="label" for="name">
                Nombre del {{ optionSelect === services ? 'servicio' : 'producto' }}
              </label>
              <input
                id="name"
                class="input px-3 py-3"
                type="text"
                name="name"
                v-model="name"
                :placeholder="optionSelect === services ? 'Nombre del servicio' : 'Nombre del producto'"
                v-validate="'required'"
                :data-vv-as="optionSelect === services ? 'nombre del servicio' : 'nombre del producto'"
              >
              <span v-show="vverrors.has('name')" class="help is-danger">{{ vverrors.first('name') }}</span>
            </div>
          </div>
          <div class="column is-12">
            <div class="field control">
              <label class="label" for="quantity">
                {{ optionSelect === services ? 'Horas' : 'Número de piezas' }}
              </label>
              <input
                id="quantity"
                class="input px-3 py-3"
                type="text"
                name="quantity"
                v-model="quantity"
                :placeholder="optionSelect === services ? 'Horas' : 'Piezas'"
                v-validate="'required|numeric'"
                :data-vv-as="optionSelect === services ? 'de horas' : 'de piezas'"
                @keypress="onlyNumbers"
              >
              <span v-show="vverrors.has('quantity')" class="help is-danger">{{ vverrors.first('quantity') }}</span>
            </div>
          </div>
          <div class="column is-12">
            <div class="field control">
              <label class="label" for="unitCost">
                {{ optionSelect === services ? 'Costo por horas' : 'Costo por unidad' }}
              </label>
              <input
                id="unitCost"
                class="input px-3 py-3"
                type="text"
                name="unitCost"
                v-model="unitCost"
                placeholder="Costo"
                v-validate="'required'"
                data-vv-as="del costo"
                @keypress="onlyNumbers"
              >
              <span v-show="vverrors.has('unitCost')" class="help is-danger">{{ vverrors.first('unitCost') }}</span>
            </div>
          </div>
          <div class="column is-12">
            <div class="file is-boxed is-justify-content-center">
              <label class="file-label">
                <input
                  class="file-input"
                  type="file"
                  name="imageMain"
                  @change="setFileMain"
                  v-validate="'required|ext:jpeg,jpg,png|size:1000'"
                  accept=".jpeg,.jpg,.png"
                  data-vv-as="la imagen"
                >
                <span class="file-cta">
                  <template v-if="previewImageMain">
                    <img :src="previewImageMain" alt="imagen">
                  </template>
                  <template v-else>
                    <span class="file-icon">
                      <ion-icon class="icon_upload" name="cloud-upload-outline" />
                    </span>
                    <span class="file-label has-text-centered">
                      Seleccione una imagen
                    </span>
                  </template>
                </span>
              </label>
            </div>
            <span v-show="vverrors.has('imageMain')" class="help is-danger">{{ vverrors.first('imageMain') }}</span>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is_outlined"
          :disabled="loading"
          @click="$emit('close-modal')"
        >
          Cancelar
        </button>
        <button
          class="button btn_violet"
          :disabled="loading"
          :class="{ 'is-loading' : loading }"
          @click="setDataAction"
        >
          Guardar
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalAddProductService',
  data () {
    return {
      optionSelect: 2,
      services: 2,
      product: 1,
      name: null,
      quantity: null,
      unitCost: null,
      previewImageMain: null,
      imageFileMain: null,
      loading: false
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    addProduct: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    onlyNumbers (evt) {
      evt = (!evt) ? window.event : evt
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async setFileMain (event) {
      if (event) {
        const [file] = event.target.files
        // Preview
        if (file) {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = e => {
            this.previewImageMain = e.target.result
          }
          this.imageFileMain = { key: event.target.name, file }
        }
      }
    },
    async setDataAction () {
      if (await this.$validator.validateAll()) {
        this.loading = true
        const dataItem = {
          name: this.name,
          total: this.quantity,
          price: this.unitCost,
          productType: this.optionSelect,
          image: this.imageFileMain.file
        }
        const resp = this.addProduct(dataItem)
        if (resp.success) {
          this.name = null
          this.quantity = null
          this.unitCost = null
        }
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal_custom {
    .button {
      width: 140px;
    }
  }
  .text_16 {
    line-height: 1.69;
  }

  .is-checkradio[type="radio"] + label::after, .is-checkradio[type="radio"] + label:after {
    background: #96D064;
  }
  .is-checkradio[type="radio"]:hover:not([disabled]) + label::before,
  .is-checkradio[type="radio"]:hover:not([disabled]) + label:before {
    border-color: #96D064 !important;
  }
  .is-checkradio[type="radio"]:checked + label::before,
  .is-checkradio[type="radio"]:checked + label:before {
    border: 0.1rem solid #96D064;
  }

  .icon_upload {
    font-size: 30px;
  }
</style>
